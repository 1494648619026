<template>
  <div>
    <div class="authorizeAndPermitTitleContainer">
      <div class="authorizeAndPermitTitleHeader flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("授权许可") }}</div>
      </div>
      <div class="authorizeAndPermitTitleFooter">
        <div class="flowPathContainer">
          <div class="title">{{ $fanyi("普通流程") }}</div>
          <div class="flexAndCenter">
            <div>{{ $fanyi("商品到货检品") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("上架仓库") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("提出配送单") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("商品从仓库取出") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("确认商品") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("打包装箱") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("配送单报价") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("入金") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("出货") }}</div>
          </div>
        </div>
        <div class="flowPathContainer" style="margin-top: 26px">
          <div class="title">{{ $fanyi("当您授权给我们配送单提出权限后") }}</div>
          <div class="flexAndCenter">
            <div>{{ $fanyi("商品到货检品") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("打包装箱") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("负责人为您提配送单") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("配送单报价") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/2.png" alt="" class="twoTypeImage">
            <div>{{ $fanyi("入金") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("出货") }}</div>
          </div>
        </div>
        <div class="flowPathContainer" style="margin-top: 26px;border-bottom:none;">
          <div class="title">{{ $fanyi("当您授权给我们出金权限以及提出配送单权限后") }}</div>
          <div class="flexAndCenter">
            <div>{{ $fanyi("商品到货检品") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("打包装箱") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
            <div>{{ $fanyi("负责人为您提配送单") }}</div>
            <img src="../../../../assets/userAuthorizeAndPermit/3.png" alt="" class="threeTypeImage">
            <div>{{ $fanyi("出货") }}</div>
          </div>
        </div>
        <div class="merit">
          <div>{{ $fanyi("优点1：缩短发货流程，更快到达日本") }}；</div>
          <div>{{ $fanyi("优点2：每天不需要确认商品状态，只要向负责人传达固定的发货规则，就可以随时发货") }}；</div>
          <div>{{ $fanyi("当您授权后：同意商品配送委托时，可缩短交货期1天以上") }}。</div>
        </div>
      </div>
      <div class="authorizeFunctionContainer">
        <div class="authorizeAndPermitTitleHeader flexAndCenter">
          <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("授权方法") }}</div>
        </div>
        <div class="authorizeFunction flexAndCenter">
          <div>{{ $fanyi("对需要开通的授权内容进行确认") }}</div>
          <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
          <div>{{ $fanyi("点击授权按钮，点击“确认”授权。") }}</div>
          <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
          <div>{{ $fanyi("常用收件地址与进口商信息确认好") }}</div>
          <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
          <div>{{ $fanyi("与负责人联系") }}</div>
          <img src="../../../../assets/userAuthorizeAndPermit/1.png" alt="" class="oneTypeImage">
          <div>{{ $fanyi("授权完成") }}</div>
        </div>
      </div>
      <div class="authorizeContentContainer">
        <div class="authorizeAndPermitTitleHeader flexAndCenter">
          <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("授权内容") }}</div>
        </div>
        <div class="authorizeContentFooter">
          <div class="authorizeContent">
            <div class="title">1、{{ $fanyi("授权RAKUMART公司提交配送委托") }}</div>
            <div class="newExplain">
              <div>{{
                  $fanyi("许可后，关于已经反映到仓库的商品，只有负责人可以操作，如果想要变动的话，请向负责人确认后进行调整")
                }}。
              </div>
              <div>{{
                  $fanyi("亚马逊交货的商品可以随时提交配送委托，也可以传达给负责人进行操作")
                }}。
              </div>
            </div>
            <div class="deliveryEntrustContainer">
              <div class="flexAndCenter" style="margin-top: 30px">{{ $fanyi("当前状态") }}：
                <div class="colorB4272B fontWeightBold">
                  {{ $store.state.userInfo.is_encasement_authorized == 0 ? $fanyi("未授权") : $fanyi("已开通授权") }}
                </div>
              </div>
              <div class="flexAndCenter btnListContainer">
                <div style="background: #B4272B;margin-right: 20px"
                     v-if="$store.state.userInfo.is_encasement_authorized == 1"
                     @click="updateDefaultAddressDialogVisible=true;updateStatus=true;">
                  既定の住所変更
                </div>
                <div
                    :style="$store.state.userInfo.is_encasement_authorized == 0?'background:#50C136':'background: #B4272B'"
                    @click="getEncasementAuthorized($store.state.userInfo.is_encasement_authorized)">
                  {{ $store.state.userInfo.is_encasement_authorized == 0 ? $fanyi("授权") : $fanyi("取消授权") }}
                </div>
              </div>
            </div>
          </div>
          <div class="authorizeContent" style="margin-top: 26px;border-bottom: none">
            <div class="title">2、
              {{ $fanyi("负责人根据订单及配送委托书的余额给予扣款收款权限，余额不足时请再次汇款") }}
            </div>
            <div class="deliveryEntrustContainer">
              <div class="flexAndCenter" style="margin-top: 30px">{{ $fanyi("当前状态") }}：
                <div class="colorB4272B fontWeightBold">
                  {{ $store.state.userInfo.is_pay_authorized == 0 ? $fanyi("未授权") : $fanyi("已开通授权") }}
                </div>
              </div>
              <div class="flexAndCenter btnListContainer" style="padding-bottom: 0">
                <div :style="$store.state.userInfo.is_pay_authorized == 0?'background:#50C136':'background: #B4272B'"
                     @click="updatePayAuthorized($store.state.userInfo.is_pay_authorized)">
                  {{ $store.state.userInfo.is_pay_authorized == 0 ? $fanyi("授权") : $fanyi("取消授权") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="authorizeCancelContainer">
        <div class="authorizeAndPermitTitleHeader flexAndCenter">
          <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("授权取消") }}</div>
        </div>
        <div class="authorizeContentFooter">
          <div class="authorizeContent" style="padding-bottom:26px">
            <div class="title">1、{{ $fanyi("取消配送委托权限") }}</div>
            <div class="authorizeContentText">
              {{ $fanyi("权限许可后，已经进行了包装作业，如果想被取消的话，请尽快联系负责人。") }}
            </div>
          </div>
          <div class="authorizeContent" style="margin-top: 26px;border-bottom: none">
            <div class="title">2、{{
                $fanyi("余额收款支付权限撤销")
              }}
            </div>
            <div class="authorizeContentText">
              {{ $fanyi("可随时取消。") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="opinionDialogContainer">
      <div class="defaultAddressDeficiencyDialogVisibleContainer">
        <!--入金授权完成弹窗-->
        <el-dialog
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="payDialogVisible"
            width="400px">
          <div class="payDialogContainer">
            <div class="flexAndCenter header">
              <div>{{ $fanyi("注意") }}：</div>
              <i class="el-icon-close" @click="payDialogVisible=false"></i>
            </div>
            <div>
              {{
                $store.state.userInfo.is_pay_authorized === 0 ? $fanyi("订单入金，配送单入金的授权设定已经取消，衷心感谢您的信赖。") : $fanyi("订单入金，配送单入金的授权设定已经完成，衷心感谢您的信赖。")
              }}
            </div>
            <div class="flex footerBtn">
              <div @click="payDialogVisible=false">{{ $fanyi("确定") }}</div>
            </div>
          </div>
        </el-dialog>
        <!--装箱授权地址未完成弹窗-->
        <el-dialog
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="defaultAddressDeficiencyDialogVisible"
            width="400px">
          <div class="payDialogContainer">
            <div class="flexAndCenter header">
              <div>{{ $fanyi("注意") }}：</div>
              <i class="el-icon-close" @click="defaultAddressDeficiencyDialogVisible=false;"></i>
            </div>
            <div>
              {{ $fanyi("常用地址设定未完成，请同时设定常用进口商地址和常用收件址。") }}
            </div>
            <div class="flex footerBtn">
              <div @click="updateDefaultAddressDialogVisible=true;defaultAddressDeficiencyDialogVisible=false;">
                {{ $fanyi("确定") }}
              </div>
            </div>
          </div>
        </el-dialog>
        <!--装箱授权完成弹窗-->
        <el-dialog
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="encasementAuthorizedAccomplishDialogVisible"
            width="400px">
          <div class="payDialogContainer">
            <div class="flexAndCenter header">
              <div>{{ $fanyi("注意") }}：</div>
              <i class="el-icon-close"
                 @click="encasementAuthorizedAccomplishDialogVisible=false;"></i>
            </div>
            <div>
              {{
                $fanyi("配送单提出权限设定已经完成，授权许可后，已经在进行打包工作，如果想要被取消的话请务必尽早与负责人联系。")
              }}
            </div>
            <div class="flex footerBtn">
              <div @click="encasementAuthorizedAccomplishDialogVisible=false;">
                {{ $fanyi("确定") }}
              </div>
            </div>
          </div>
        </el-dialog>
        <!--设置装箱授权地址-->
        <el-dialog
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="updateDefaultAddressDialogVisible"
            width="1550px">
          <div class="payDialogContainer">
            <div class="flexAndCenter header">
              <div>{{ $fanyi("常用地址") }}：</div>
              <i class="el-icon-close" @click="updateDefaultAddressDialogVisible=false"></i>
            </div>
            <div class="defaultAddressHeader">
              <div class="fontSize12 colorB4272B">
                注意：{{ $fanyi("常用进口商地址和常用收件地址相同也可以") }}
              </div>
              <div class="defaultAddressHeaderRight">
                <div>国際運送会社選択：</div>
                <el-select v-model="value" size="mini">
                  <el-option
                      v-for="item in logisticsList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div style="width: 1500px;overflow-x: scroll">
              <div class="addressListHeader flexAndCenter">
                <div class="No">No</div>
                <div class="name">{{ $fanyi("氏名") }}</div>
                <div class="name">{{ $fanyi("ローマ字") }}</div>
                <div class="name">{{ $fanyi("公司名") }}</div>
                <div class="zip">{{ $fanyi("郵  便") }}</div>
                <div class="address">{{ $fanyi("住  所") }}</div>
                <div class="address">{{ $fanyi("ローマ字") }}</div>
                <div class="tel">{{ $fanyi("座机") }}</div>
                <div class="tel">{{ $fanyi("手机") }}</div>
                <div class="setting">{{ $fanyi("常用輸入者住所") }}</div>
                <div class="setting">{{ $fanyi("常用配送先") }}</div>
              </div>
              <div style="height: 600px;overflow-y: scroll;width: 1500px">
                <div v-for="(item,index) in addressList" :key="index" class="flexAndCenter addressListFooter">
                  <div class="No">{{ index + 1 }}</div>
                  <div class="name">{{ item.name }}</div>
                  <div class="name">{{ item.name_rome }}</div>
                  <div class="name">{{ item.company }}</div>
                  <div class="zip">{{ item.zip }}</div>
                  <div class="address">{{ item.address }}</div>
                  <div class="address">{{ item.address_rome }}</div>
                  <div class="tel">{{ item.tel }}</div>
                  <div class="tel">{{ item.mobile }}</div>
                  <div class="setting">
                    <div :class="item.default_importer === 0?'btn':'newBtn'" @click="updateImporterAddress(item)">
                      {{ item.default_importer === 0 ? $fanyi("设定为常用进口商") : $fanyi("已设为常用") }}
                      <!--                      {{ $fanyi("设定为常用进口商") }}-->
                    </div>
                  </div>
                  <div class="setting">
                    <div :class="item.default_receiver === 0?'btn':'newBtn'" @click="updateReceiverAddress(item)">
                      {{ item.default_receiver === 0 ? $fanyi("设定为常用收件地址") : $fanyi("已设为常用") }}
                      <!--                      {{ $fanyi("已设为常用") }}-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex footerBtn" style="margin-top: 20px">
              <div @click="submitDefaultAddress">
                {{ $fanyi("确定") }}
              </div>
            </div>
          </div>
        </el-dialog>
        <!--装箱授权取消，有未处理完成的装好箱的，或者配送单时，不能取消装箱授权-->
        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="noCancelAbilityDialogVisible"
            width="400px">
          <div class="payDialogContainer">
            <div class="flexAndCenter header">
              <div>{{ $fanyi("注意") }}：</div>
              <i class="el-icon-close"
                 @click="noCancelAbilityDialogVisible=false;"></i>
            </div>
            <div>
              {{ errorText }}
            </div>
            <div class="flex footerBtn">
              <div @click="noCancelAbilityDialogVisible=false;">
                {{ $fanyi("确定") }}
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payDialogVisible: false,//入金授权完成弹窗
      updateDefaultAddressDialogVisible: false,//更新装箱授权地址完成弹窗
      defaultAddressDeficiencyDialogVisible: false,//装箱授权地址未完成弹窗
      encasementAuthorizedAccomplishDialogVisible: false,//装箱授权完成弹窗
      noCancelAbilityDialogVisible: false,//不能取消装箱授权弹窗
      addressList: [],
      errorText: '',
      logisticsList: [],
      value: 14,
      updateStatus: false,
    }
  },
  created() {
    this.$api.internationalLogisticsTransportation().then((res) => {
      res.data.forEach((item) => {
        if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
          item.name += '―定額会員限定ルート'
        }
      })
      this.logisticsList = res.data;
    });
    let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
    if (userData.logistics_id !== '') {
      this.value = Number(userData.logistics_id)
    }
    this.$api.userAddressList().then((res) => {
      this.addressList = res.data.data;
    })
  },
  methods: {
    //更新支付授权状态
    updatePayAuthorized(e) {
      this.$api.payAuthorized({
        authorized: e == 1 ? 0 : 1
      }).then((res) => {
        if (res.code === 0) {
          this.$api.japanGetUserInfo().then((res) => {
            this.$store.commit("userData", res.data);
            this.payDialogVisible = true;
          });
        }
      });
    },
    //获取客户装箱授权状态及地址信息
    getEncasementAuthorized(e) {
      if (e === 1) {
        this.updateEncasementAuthorized(0);
      } else {
        let count = 0;
        this.addressList.forEach((item) => {
          count += item.default_importer;
          count += item.default_receiver;
        })
        count < 2 ? this.defaultAddressDeficiencyDialogVisible = true : this.updateDefaultAddressDialogVisible = true;
      }
    },
    //更新装箱授权状态
    updateEncasementAuthorized(val) {
      this.$api.encasementAuthorized({
        authorized: val
      }).then((res) => {
        if (res.code != 0) {
          this.errorText = res.msg;
          this.noCancelAbilityDialogVisible = true;
          return
        }
        this.$forceUpdate();
        if (val === 0) {
          this.encasementAuthorizedAccomplishDialogVisible = true;
        }
        this.$api.japanGetUserInfo().then((res) => {
          this.$store.commit("userData", res.data);
        });
      });
    },
    //提交默认的收货地址
    submitDefaultAddress() {
      let count = 0;
      let data = {
        default_importer: '',
        default_receiver: ''
      }
      this.addressList.forEach((item) => {
        count += item.default_importer;
        count += item.default_receiver;
        if (item.default_importer !== 0) {
          data.default_importer = item.id
        }
        if (item.default_receiver !== 0) {
          data.default_receiver = item.id
        }
      })
      if (count < 2) {
        this.$message.error(this.$fanyi('常用地址设定未完成，请同时设定常用进口商地址和常用收件址。'))
        return
      }
      this.$api.setDefaultAddress(data).then((res) => {
        if (res.code === 0) {
          this.updateDefaultAddressDialogVisible = false;
          this.$api.userAddressList().then((res) => {
            this.addressList = res.data.data;
          })
        }
      })
      this.$api.setDefaultLogistics({logistics_id: this.value}).then((res) => {
      })
      if (this.updateStatus === false) {
        this.updateEncasementAuthorized(1);
      } else {
        this.updateStatus = false;
      }
    },
    //更新默认进口商地址
    updateImporterAddress(item) {
      this.addressList.forEach((addressItem) => {
        addressItem.id === item.id ? addressItem.default_importer = 1 : addressItem.default_importer = 0;
        this.$forceUpdate();
      })
    },
    //更新默认收件地址
    updateReceiverAddress(item) {
      this.addressList.forEach((addressItem) => {
        addressItem.id === item.id ? addressItem.default_receiver = 1 : addressItem.default_receiver = 0;
        this.$forceUpdate();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.authorizeAndPermitTitleContainer {
  border-radius: 10px;
  overflow: hidden;

  .authorizeAndPermitTitleHeader {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
  }

  .authorizeAndPermitTitleFooter {
    background: #fff;
    padding: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .flowPathContainer {
      padding-bottom: 26px;
      border-bottom: 1px dashed #DFDFDF;

      .title {
        color: #B4272B;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
      }

      .flexAndCenter {
        div {
          width: 106px;
          height: 50px;
          background: linear-gradient(0deg, #E7E7E7, #FBFBFB);
          border: 1px solid #DFDFDF;
          border-radius: 6px;
          color: #000000;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .oneTypeImage {
          width: 16px;
          height: 13px;
          margin: 0 1px 0 2px;
        }

        .twoTypeImage {
          width: 390px;
          height: 13px;
          margin: 0 1px 0 2px;
        }

        .threeTypeImage {
          width: 639px;
          height: 13px;
          margin: 0 1px 0 2px;
        }
      }
    }

    .merit {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      font-weight: bold;
    }
  }

  .authorizeFunctionContainer {
    margin-top: 26px;
    border-radius: 10px;
    overflow: hidden;

    .authorizeFunction {
      padding: 26px;
      background: #fff;

      div {
        width: 106px;
        height: 50px;
        background: linear-gradient(0deg, #E7E7E7, #FBFBFB);
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        color: #000000;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:nth-child(5), div:nth-child(1), div:nth-child(3) {
        width: 260px;
      }

      img {
        margin: 0 6px;
      }
    }
  }

  .authorizeContentContainer {
    margin-top: 26px;
    border-radius: 10px;
    overflow: hidden;

    .authorizeContentFooter {
      padding: 26px;
      background: #fff;

      .authorizeContent {
        border-bottom: 1px dashed #DFDFDF;

        .title {
          color: #B4272B;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .newExplain {
          margin-top: 19px;
          line-height: 20px;
          font-size: 14px;
          color: #000000;
        }

        .deliveryEntrustContainer {
          font-size: 14px;

          .btnListContainer {
            margin-top: 9px;
            padding-bottom: 26px;

            div {
              padding: 9px 26px;
              color: #fff;
              border-radius: 8px;
              cursor: pointer;
              font-size: 14px;
            }
          }
        }
      }
    }
  }


  .authorizeCancelContainer {
    margin-top: 26px;
    border-radius: 10px;
    overflow: hidden;

    .authorizeContentFooter {
      padding: 26px;
      background: #fff;

      .authorizeContent {
        border-bottom: 1px dashed #DFDFDF;

        .title {
          color: #B4272B;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .authorizeContentText {
          margin-top: 19px;
          font-size: 14px;
          color: #000;
        }
      }
    }
  }
}

.opinionDialogContainer {
  margin-top: 10vh !important;

  .defaultAddressDeficiencyDialogVisibleContainer {
    /deep/ .el-dialog {
      box-sizing: border-box;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  /deep/ .el-dialog__header, /deep/ .el-dialog__body {
    padding: 0;
  }

  .payDialogContainer {
    padding: 20px;

    .header {
      justify-content: space-between;
      margin-bottom: 10px;

      div {
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }

      /deep/ .el-icon-close {
        font-size: 18px;
        color: #000;
        cursor: pointer;
      }
    }

    .defaultAddressHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .defaultAddressHeaderRight {
        display: flex;
        align-items: center;
      }
    }

    .footerBtn {
      display: flex;
      justify-content: flex-end;

      div {
        width: 70px;
        height: 38px;
        line-height: 38px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        background: #B4272B;
      }
    }
  }

  .addressListHeader {
    margin-top: 10px;
    width: 1500px;

    div {
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #B4272D;
      color: #fff;
    }
  }

  .addressListFooter {
    border-bottom: 1px solid;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
    }

    .btn, .newBtn {
      width: 250px;
      height: 40px !important;
      line-height: 40px;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;
      border: 1px solid;
      max-height: 40px;
      min-height: 40px;
    }

    .newBtn {
      background: #B4272D;
      color: #fff;
    }
  }

  .addressListFooter:last-child {
    border-bottom: none;
  }

  .No {
    width: 60px;
  }

  .name {
    width: 100px;
  }

  .zip {
    width: 80px;
  }

  .address {
    width: 160px;
  }

  .tel {
    width: 120px;
  }

  .setting {
    width: 270px;
  }

}
</style>
